<template>
  <div v-if="advisor">
    <se-top-up-balance-popup
      v-if="notEnoughMoney || notEnoughMoneyInbox"
      :advisor="advisor"
      :chat-mode="chatMode"
      @close="closeThisModal"
    />
  </div>
</template>


<script setup lang="ts">
import {storeToRefs} from "pinia";
import {computed, onMounted, ref, watch} from 'vue'
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {usersStore} from '@/src/store/users'
import {chatStore} from '@/src/store/chat'
import {inboxStore} from '@/src/store/inbox'
import SeTopUpBalancePopup from "@/src/modules/chat/components/popups/top-up-balance-popup.vue";

const chat = chatStore()
const {chatInfo, savedAction, savedParam, savedAdvisor, notEnoughMoney} = storeToRefs(chat)
const {setNotEnoughMoney, getChatModeByAction} = chat

const inbox = inboxStore()
const {notEnoughMoneyInbox, message} = storeToRefs(inbox)
const {setNotEnoughMoneyInbox, openMessageModal} = inbox

const user = usersStore()
const {currentUser, promotions} = storeToRefs(user)
const {setChargeAmount} = user

const loading = ref(false);
const advisor = ref({});

const {$api} = getGlobalProperties()

const chatMode = computed(() => {
  return chatInfo.value?.mode ? chatInfo.value.mode : (savedAction.value ? getChatModeByAction(savedAction.value).mode : '')
})

onMounted(() => {
  if (savedAdvisor.value) {
    getAdvisor();
  }
})

const getAdvisor = () => {
  if (savedParam.value === null && savedAdvisor.value?.id) {
    advisor.value = savedAdvisor.value;
    return false;
  }
  
  loading.value = true;
  $api.get(`/user/${savedParam.value}`)
    .then((res: any) => {
      advisor.value = res.data;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    })
};
watch([notEnoughMoney, notEnoughMoneyInbox], ([val1, val2]) => {
  if (val1 || val2) {
    getAdvisor();
  }
})

const closeThisModal = () => {
  setNotEnoughMoney(false)
  setNotEnoughMoneyInbox(false)
  setChargeAmount(null)
};

</script>

<script lang="ts">
export default {
  name: 'SeNotEnoughMoneyPopup',
};
</script>
